.oi-page-content{
    ul{
        list-style: initial !important;
        padding-left: 40px;
        li{
            margin: 10px 0px;
        }
    }
    ol{
        &[type="a"]{
            list-style-type: lower-alpha;
        }
        padding-left: 40px;
        li{
            margin: 10px 0px;
        }
    }
}