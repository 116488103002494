/*
* Based on Skeleton by Dave Gamache (www.getskeleton.com)
* Modified by Vasterad
*/

/* Table of Contents
==================================================
    #Base (1180 Grid)
    #Desktop (960 Grid)
    #Tablet (Portrait)
    #Mobile (Portrait)
    #Mobile (Landscape)
    #Clearing */

/* #Base (1180 Grid)
================================================== */

.container {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding: 0;
    .column, .columns {
        float: left;
        display: inline;
        margin-left: 10px;
        margin-right: 10px;
    }
}
.popup-container,
.container-fluid {
    position: relative;
    padding: 0;
    .column, .columns {
        float: left;
        display: inline;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.row {
    margin-bottom: 20px;
}

.item-row{
    margin-right: -10px;
    margin-left: -10px;
}

/* Nested Column Classes */

.column.alpha, .columns.alpha {
    margin-left: 0;
}

.column.omega, .columns.omega {
    margin-right: 0;
}

/* Base Grid */
.popup-container,
.container {
    .one {
        &.column, &.columns {
            width: 55px;
        }
    }
    .two.columns {
        width: 130px;
    }
    .three.columns {
        width: 205px;
    }
    .four.columns {
        width: 280px;
    }
    .five.columns {
        width: 355px;
    }
    .five-point-three.columns {
        width: 380px;
    }
    .six.columns {
        width: 430px;
    }
    .seven.columns {
        width: 505px;
    }
    .eight.columns {
        width: 580px;
    }
    .nine.columns {
        width: 655px;
    }
    .ten.columns {
        width: 730px;
    }
    .eleven.columns {
        width: 805px;
    }
    .twelve.columns {
        width: 880px;
    }
    .thirteen.columns {
        width: 955px;
    }
    .fourteen.columns {
        width: 1030px;
    }
    .fifteen.columns {
        width: 1105px;
    }
    .sixteen.columns {
        width: 1180px;
    }
    .one-third.column {
        width: 380px;
    }
    .two-thirds.column {
        width: 780px;
    }
    .twelve {
        &.sidebar-right.columns {
            width: 860px;
            margin-right: 30px;
        }
        &.sidebar-left.columns {
            width: 860px;
            margin-left: 30px;
        }
    }
    .four {
        &.shop.columns {
            width: 273px;
            margin: 0 0 0 20px;
        }
        &.masonry-item.columns {
            width: 273px;
            margin: 0 20px 0 0;
        }
    }
}

body.boxed, .fullwidth-element {
    width: 1290px;
}

.fullwidth {
    .parallax-content {
        width: 1180px;
    }
    .fullwidth-element.home-slider {
        width: 100%;
    }
}

@media only screen and (min-width: 1290px) and (max-width: 1390px) {
    body.boxed {
        width: 100%;
    }
    .fullwidth-element {
        width: 100%;
        img {
            width: 100%;
        }
    }
}

/* #Dekstop (960 Grid)
================================================== */

/* Note: Design for a width of 960px */

@media only screen and (min-width: 960px) and (max-width: 1389px) {
    .container {
        position: relative;
        width: 960px;
        margin: 0 auto;
        padding: 0;
        .column, .columns {
            float: left;
            display: inline;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .row {
        margin-bottom: 20px;
    }
    /* Nested Column Classes */
    .column.alpha, .columns.alpha {
        margin-left: 0;
    }
    .column.omega, .columns.omega {
        margin-right: 0;
    }
    /* Base Grid */
    .popup-container,
    .container {
        .one {
            &.column, &.columns {
                width: 40px;
            }
        }
        .two.columns {
            width: 100px;
        }
        .three.columns {
            width: 160px;
        }
        .four.columns {
            width: 220px;
        }
        .five.columns {
            width: 280px;
        }
        .five-point-three.columns {
            width: 300px;
        }
        .six.columns {
            width: 340px;
        }
        .seven.columns {
            width: 400px;
        }
        .eight.columns {
            width: 460px;
        }
        .nine.columns {
            width: 520px;
        }
        .ten.columns {
            width: 580px;
        }
        .eleven.columns {
            width: 640px;
        }
        .twelve.columns {
            width: 700px;
        }
        .thirteen.columns {
            width: 760px;
        }
        .fourteen.columns {
            width: 820px;
        }
        .fifteen.columns {
            width: 880px;
        }
        .sixteen.columns {
            width: 940px;
        }
        .one-third.column {
            width: 300px;
        }
        .two-thirds.column {
            width: 620px;
        }
        .twelve {
            &.sidebar-right.columns {
                width: 680px;
                margin-right: 30px;
            }
            &.sidebar-left.columns {
                width: 680px;
                margin-left: 30px;
            }
        }
        .four {
            &.shop.columns {
                width: 213px;
                margin: 0 0 0 20px;
            }
            &.masonry-item.columns {
                width: 213px;
                margin: 0 20px 0 0;
            }
        }
    }
    body.boxed, .fullwidth-element {
        width: 1020px;
    }
    .fullwidth {
        .parallax-content, .fullwidth-element.home-slider {
            width: 940px;
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 1040px) {
    body.boxed, .fullwidth-element {
        width: 100%;
    }
}

/* #Tablet (Portrait)
================================================== */

/* Note: Design for a width of 768px */

@media only screen and (min-width: 768px) and (max-width: 990px) {
    .container {
        width: 768px;
        .column, .columns {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .column.alpha, .columns.alpha {
        margin-left: 0;
        margin-right: 10px;
    }
    .column.omega, .columns.omega {
        margin-right: 0;
        margin-left: 10px;
    }
    .alpha.omega {
        margin-left: 0;
        margin-right: 0;
    }
    .popup-container,
    .container {
        .one {
            &.column, &.columns {
                width: 28px;
            }
        }
        .two.columns {
            width: 76px;
        }
        .three.columns {
            width: 124px;
        }
        .four.columns {
            width: 172px;
        }
        .five.columns {
            width: 220px;
        }
        .five-point-three.columns {
            width: 236px;
        }
        .six.columns {
            width: 268px;
        }
        .seven.columns {
            width: 316px;
        }
        .eight.columns {
            width: 364px;
        }
        .nine.columns {
            width: 412px;
        }
        .ten.columns {
            width: 460px;
        }
        .eleven.columns {
            width: 508px;
        }
        .twelve.columns {
            width: 556px;
        }
        .thirteen.columns {
            width: 604px;
        }
        .fourteen.columns {
            width: 652px;
        }
        .fifteen.columns {
            width: 700px;
        }
        .sixteen.columns {
            width: 748px;
        }
        .one-third.column {
            width: 236px;
        }
        .two-thirds.column {
            width: 492px;
        }
        .twelve {
            &.sidebar-right.columns {
                width: 546px;
                margin-right: 20px;
            }
            &.sidebar-left.columns {
                width: 546px;
                margin-left: 20px;
            }
        }
        .four {
            &.shop.columns {
                width: 258px;
                margin: 0 0 0 20px;
            }
            &.masonry-item.columns {
                width: 258px;
                margin: 0 20px 0 0;
            }
        }
    }
    body.boxed, .fullwidth-element {
        width: 100%;
    }
    .fullwidth {
        .parallax-content {
            width: 748px;
        }
        .fullwidth-element.home-slider {
            width: 100%;
        }
    }
}

/*  #Mobile (Portrait)
================================================== */

/* Note: Design for a width of 320px */

@media only screen and (max-width: 767px) {
    .popup-container,
    .container {
        width: 95%;
        .columns, .column {
            margin: 0;
        }
        .one {
            &.column, &.columns {
                width: 100%;
            }
        }
        .two.columns, .three.columns, .four.columns, .five.columns, .five-point-three.columns, .six.columns, .seven.columns, .eight.columns, .nine.columns, .ten.columns, .eleven.columns, .twelve.columns, .thirteen.columns, .fourteen.columns, .fifteen.columns, .sixteen.columns, .one-third.column, .two-thirds.column {
            width: 100%;
        }
        .twelve {
            &.sidebar-right.columns, &.sidebar-left.columns {
                width: 100%;
            }
        }
        .five.sidebar-right.columns, .eleven.sidebar-right.columns {
            width: 100%;
        }
        .four {
            &.shop.columns, &.masonry-item.columns {
                width: 100%;
                margin: 0;
            }
        }
        .twelve.sidebar-left.columns {
            margin-left: 0;
        }
        .fourteen.carousel.columns {
            width: 240px;
        }
        .one.carousel.column {
            width: 30px;
        }

        .products{
            .four.columns {
                width:50%;
                padding: 0 3px;
            }
            .four.columns.in-stock-cont {
                width:100%;
            }
        }
    }
    body.boxed, .fullwidth-element {
        width: 100%;
    }
    .fullwidth {
        .fullwidth-element.home-slider {
            width: 100%;
        }
        .parallax-content {
            width: 300px;
        }
    }
}

/* #Mobile (Landscape)
================================================== */

/* Note: Design for a width of 480px */

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .popup-container,
    .container {
        width: 95%;
        .columns, .column {
            margin: 0;
        }
        .one {
            &.column, &.columns {
                width: 100%;
            }
        }
        .two.columns, .three.columns, .four.columns, .five.columns, .five-point-three.columns, .six.columns, .seven.columns, .eight.columns, .nine.columns, .ten.columns, .eleven.columns, .twelve.columns, .thirteen.columns, .fourteen.columns, .fifteen.columns, .sixteen.columns, .one-third.column, .two-thirds.column {
            width: 100%;
        }
        .twelve {
            &.sidebar-right.columns, &.sidebar-left.columns {
                width: 100%;
            }
        }
        .five.sidebar-right.columns, .eleven.sidebar-right.columns {
            width: 4100%;
        }
        .twelve.sidebar-left.columns {
            margin-left: 0;
        }
        .four {
            &.shop.columns, &.masonry-item.columns {
                width: 100%;
                margin: 0;
            }
        }
        .fourteen.carousel.columns {
            width: 340px;
        }
        .one.carousel.column {
            width: 40px;
        }

        .products{
            .five-point-three.columns,
            .five-point-three.column,
            .four.columns {
                width:50%;
                padding: 0 3px;
            }
            .four.columns.in-stock-cont {
                width:100%;
            }
        }
        .five-point-three.columns,
        .five-point-three.column {
            width:50%;
            padding: 0 3px;
        }
    }
    body.boxed, .fullwidth-element {
        width: 100%;
    }
    .fullwidth {
        .fullwidth-element.home-slider {
            width: 100%;
        }
        .parallax-content {
            width: 420px;
        }
    }
    .item-row{
        margin: 0;
    }
}
@media only screen and (max-width: 479px){
    .item-row{
        margin: 0;
    }
    .popup-container,
    .container{
        .column,
        .columns{
            width: 100%;
        }
    }

    .products{
        .four.columns {
            width:50%;
            padding: 0 3px;
        }
        .four.columns.in-stock-cont {
            width:100%;
        }
    }
}

/* #Clearing
================================================== */

/* Self Clearing Goodness */

.container:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

/* Use clearfix class on parent to clear nested columns,
or wrap each row of columns in a <div class="row"> */

.clearfix {
    &:before, &:after {
        content: '\0020';
        display: block;
        overflow: hidden;
        visibility: hidden;
        width: 0;
        height: 0;
    }
}
.item-row,
.row {
    &:before {
        content: '\0020';
        display: block;
        overflow: hidden;
        visibility: hidden;
        width: 0;
        height: 0;
    }
    &:after {
        content: '\0020';
        display: block;
        overflow: hidden;
        visibility: hidden;
        width: 0;
        height: 0;
        clear: both;
    }
}

.clearfix:after {
    clear: both;
}

@include screen-xs{
    .clearfix-xs {
        &:before, &:after {
            content: '\0020';
            display: block;
            overflow: hidden;
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }
    .clearfix-xs:after {
        clear: both;
    }
    .clearfix-xs {
        zoom: 1;
    }
}


.item-row, .row, .clearfix {
    zoom: 1;
}

/* You can also use a <br class="clear" /> to clear columns */

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.has-new-product-label {
    top: unset !important;
    bottom: 0 !important;
}