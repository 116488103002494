.package-receiving{
    margin-top: 32px;
    .place-name{
        font-weight:600;
        font-size:15px;
    }
    .opening-title{
        font-weight: 600;
    }
    .details{
        padding:15px 0px;
    }

}
.pac-container{
    z-index:100000;
}