
/* Webfont: LatoLatin-Thin */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-Thin.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-Thin.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-Thin.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: 100;
    font-display: swap;
}

/* Webfont: LatoLatin-ThinItalic */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-ThinItalic.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-ThinItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-ThinItalic.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-ThinItalic.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 100;
    font-display: swap;
}

/* Webfont: LatoLatin-Light */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-Light.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('lato/LatoLatin-Light.woff2') format('woff2'), /* Modern Browsers */
         url('lato/LatoLatin-Light.woff') format('woff'), /* Modern Browsers */
         url('lato/LatoLatin-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

/* Webfont: LatoLatin-LightItalic */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('lato/LatoLatin-LightItalic.woff2') format('woff2'), /* Modern Browsers */
         url('lato/LatoLatin-LightItalic.woff') format('woff'), /* Modern Browsers */
         url('lato/LatoLatin-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}
/* Webfont: LatoLatin-Regular */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-Regular.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-Regular.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-Regular.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

/* Webfont: LatoLatin-Italic */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-Italic.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-Italic.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-Italic.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}
/* Webfont: LatoLatin-Bold */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-Bold.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-Bold.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-Bold.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

/* Webfont: LatoLatin-BoldItalic */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-BoldItalic.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-BoldItalic.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}

/* Webfont: LatoLatin-Black */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-Black.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-Black.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-Black.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

/* Webfont: LatoLatin-BlackItalic */@font-face {
    font-family: 'Lato';
    src: url('lato/LatoLatin-BlackItalic.eot'); /* IE9 Compat Modes */
    src: url('lato/LatoLatin-BlackItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('lato/LatoLatin-BlackItalic.woff2') format('woff2'), /* Modern Browsers */
    url('lato/LatoLatin-BlackItalic.woff') format('woff'), /* Modern Browsers */
    url('lato/LatoLatin-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}