#thank-you-page {
  .message {
    color: $dark-grey;
    font-size: 20px;
    margin-bottom: 50px;
  }

  .ok-icon, .error-icon {
    display: block;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 65px;
    width: 80px;
  }

  .title {
    color: $dark-grey;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 25px;
    margin-top: 50px;
    text-transform: uppercase;
    line-height: 34px;
  }

  .thankyou-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  .guest-form {
    margin-top: 15px;
    max-width: 580px;
  }

  .info-box {
    color: $dark-grey;
    font-size: 18px;

    @include screen-sm-up {
      display: flex;
      flex-wrap: wrap;
    }

    .box {
      border: 1px solid #ebebeb;
      background-color: $white;
      margin-top: 30px;
      padding: 40px 30px;
      width: 100%;

      @include screen-sm-up {
        &:not(.box-full) {
          width: calc(50% - 10px);
        }

        &.box-l {
          margin-right: 10px;
        }

        &.box-r {
          margin-left: 10px;
        }
      }

      img {
        display: inline;
        margin-right: 15px;
      }
    }

    .newsletter-form {
      color: gray;

      @include screen-phone-up {
        display: flex;
      }

      .form-button {
        text-align: center;

        @include screen-phone-up {
          padding-top: 24px;
          text-align: inherit;
        }

        button {
          height: 40px;
          white-space: nowrap;
        }
      }

      .form-input {
        width: 100%;

        input::placeholder {
          color: gray;
        }
      }
    }
  }
  .toggle-change-email-form {
    cursor: pointer !important;
    font-size: 16px !important;
  }
}