/*
* Based on Skeleton by Dave Gamache (www.getskeleton.com)
* Modified by Vasterad
*/

/* Table of Content
==================================================
	#Reset & Basics
	#Basic Styles
	#Site Styles
	#Typography
	#Links
	#Lists
	#Images
	#Buttons
	#Forms
	#Misc */

/* #Reset & Basics (Inspired by E. Meyers)
================================================== */

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote {
    &:before, &:after {
        content: '';
        content: none;
    }
}

q {
    &:before, &:after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

table.table {
    width: 100%;
}

.no-select {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

/* #Basic Styles
================================================== */

body {
    background: $background;
    font-size: 14px;
    line-height: 24px;
    color: $mobilklimak-dark;
    -webkit-font-smoothing: antialiased;
    /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;

}

#wrapper {
    padding: 0;
    background-color: $background;
}

/* Boxed Layout Style */

body.boxed {
    margin: 0 auto;
    background: $light-grey;
}

#header {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

/* Main Font */

body, h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
}

input {
    &[type="button"], &[type="submit"], &[type="text"], &[type="password"], &[type="email"] {
        font-family: 'Lato', sans-serif;
    }
}

textarea, select, button {
    font-family: 'Lato', sans-serif;
}

/* Basic Alginment
================================================== */

#wrapper img, video {
    height: auto;
    max-width: 100%;
}

#wrapper .five-point-three img{
    width: 100%;
}

.columns.google-map img {
    max-width: none;
}

img, object, video {
    height: auto;
    display: block;
}

img {
    width: auto;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

/* Flexible Embeds */

.embed {
    position: relative;
    padding: 0px;
    padding-bottom: 56.25%;
    /* 16/9 ratio */
    height: 0;
    overflow: hidden;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.image-left {
    float: left;
    margin: 5px 15px 8px 0;
}

.image-right {
    float: right;
    margin: 5px 0 8px 15px;
    padding: 5px;
    border: 1px solid lighten($grey, 35%);
    max-width: 100%;
    -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
}

.image-left {
    padding: 5px;
    border: 1px solid lighten($grey, 35%);
    max-width: 100%;
    -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.03);
}


/* #Margin Styles
================================================== */

/* Margin Reset */

.margin-reset {
    margin: 0 !important;
}

/* Margin Top */

.margin-top-0 {
    margin-top: 0 !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px !important;
}

.margin-top-35 {
    margin-top: 35px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-top-45 {
    margin-top: 45px !important;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.margin-top-55 {
    margin-top: 55px !important;
}

/* Padding Top */

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-top-10 {
    padding-top: 10px !important;
}

.padding-top-15 {
    padding-top: 15px !important;
}

.padding-top-20 {
    padding-top: 20px !important;
}

.padding-top-25 {
    padding-top: 25px !important;
}

.padding-top-30 {
    padding-top: 30px !important;
}

.padding-top-35 {
    padding-top: 35px !important;
}

.padding-top-40 {
    padding-top: 40px !important;
}

.padding-top-45 {
    padding-top: 45px !important;
}

.padding-top-50 {
    padding-top: 50px !important;
}

.padding-top-55 {
    padding-top: 55px !important;
}

/* Padding Bottom */

.padding-bottom-0 {
    padding-bottom: 0 !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-bottom-15 {
    padding-bottom: 15px !important;
}

.padding-bottom-20 {
    padding-bottom: 20px !important;
}

.padding-bottom-25 {
    padding-bottom: 25px !important;
}

.padding-bottom-30 {
    padding-bottom: 30px !important;
}

.padding-bottom-35 {
    padding-bottom: 35px !important;
}

.padding-bottom-40 {
    padding-bottom: 40px !important;
}

.padding-bottom-45 {
    padding-bottom: 45px !important;
}

.padding-bottom-50 {
    padding-bottom: 50px !important;
}

.padding-bottom-55 {
    padding-bottom: 55px !important;
}

/* Margin Bottom */

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

.margin-bottom-30 {
    margin-bottom: 30px !important;
}

.margin-bottom-35 {
    margin-bottom: 35px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.margin-bottom-45 {
    margin-bottom: 45px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px !important;
}
/* Margin Top */

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.padding-left-25 {
    padding-left: 25px !important;
}

.padding-left-30 {
    padding-left: 30px !important;
}

.padding-left-35 {
    padding-left: 35px !important;
}

.padding-left-40 {
    padding-left: 40px !important;
}

.padding-left-45 {
    padding-left: 45px !important;
}

.padding-left-50 {
    padding-left: 50px !important;
}

.padding-left-55 {
    padding-left: 55px !important;
}

/* Margin Top */

.padding-left-0 {
    padding-left: 0 !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.padding-left-20 {
    padding-left: 20px !important;
}

.padding-left-25 {
    padding-left: 25px !important;
}

.padding-left-30 {
    padding-left: 30px !important;
}

.padding-left-35 {
    padding-left: 35px !important;
}

.padding-left-40 {
    padding-left: 40px !important;
}

.padding-left-45 {
    padding-left: 45px !important;
}

.padding-left-50 {
    padding-left: 50px !important;
}

.padding-left-55 {
    padding-left: 55px !important;
}

/* Margin Left */

.margin-left-0 {
    margin-left: 0 !important;
}

/* Margin Right */

.margin-right-5 {
    margin-right: 5px !important;
}

/* Margin Right */

.margin-right-5 {
    margin-right: 5px !important;
}

/* #Typography
================================================== */

h1, h2, h3, h4, h5, h6 {
    color: $dark-grey;
    font-weight: 500;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    font-weight: inherit;
}

h1 {
    font-size: 28px;
    line-height: 50px;
}

h2 {
    font-size: 24px;
    line-height: 30px;
}

h3 {
    font-size: 20px;
    line-height: 34px;
}

h4 {
    font-size: 18px;
    line-height: 30px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-size: 14px;
    line-height: 21px;
}

p {
    margin: 0 0 15px 0;
    line-height: 24px;
    color: $mobilklimak-dark;

    img {
        margin: 0;
    }

    &.lead {
        font-size: 21px;
        line-height: 27px;
        color: darken($grey, 7%);
    }
}

em {
    font-style: italic;
}

strong {
    font-weight: 600;
    color: $dark-grey;
}

small {
    font-size: 80%;
}

hr {
    border: solid lighten($grey, 33%);
    border-width: 1px 0 0;
    clear: both;
    margin: 10px 0 30px;
    height: 0;
}

/* #Links
================================================== */

a {
    color: darken($light-pink, 20%);
    text-decoration: none;
    outline: 0;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    -transition: color 0.2s ease-in-out;

    &:hover {
        color: darken($mobilklimak-main, 10%);
    }
}

p a {
    line-height: inherit;

    &:visited {
        line-height: inherit;
    }
}

/* #Lists
================================================== */

ul, ol {
    margin: 0;
}

ul {
    list-style: none outside;
}

ol {
    list-style: decimal;
    margin-left: 30px;
}

ul {
    &.square, &.circle, &.disc {
        margin-left: 30px;
    }

    &.square {
        list-style: square outside;
    }

    &.circle {
        list-style: circle outside;
    }

    &.disc {
        list-style: disc outside;
    }

    ul, ol {
    }
}

ol {
    ol, ul {
    }
}

ul {
    ul li, ol li {
    }
}

ol {
    ol li, ul li {
    }
}

li {
    line-height: 18px;
}

ul.large li, li p {
    line-height: 21px;
}

/* #Forms
================================================== */

input {
    &[type="text"], &[type="password"], &[type="email"] {
        padding: 10px 12px;
        outline: none;
        font-size: 14px;
        color: $input-color;
        margin: 0;
        max-width: 100%;
        height: 50px;
        background-color: #F0F2F5;
        display: block;
        border: 1px solid #cecece;
        font-weight: 400;
        &[readonly],
        &[readonly]:focus,
        &[disabled] {
            background-color: #e8e8e8;
            border: 1px solid #bababa;
            cursor: not-allowed;
            &.has-option{
                cursor: auto;
                background-color: #F0F2F5;
                border: 1px solid #cecece;
            }
        }
    }
}

textarea {
    padding: 10px 12px;
    outline: none;
    font-size: 13px;
    color: $input-color;
    margin: 0;
    max-width: 100%;
    display: block;
    background: $background;
    border: 1px solid #cecece;
    font-weight: 600;
}

select {
    padding: 15px 12px;
    outline: none;
    font-size: 13px;
    color: $input-color;
    margin: 0;
    max-width: 100%;
    display: block;
    background: #F0F2F5;
    border: 1px solid #cecece;
    font-weight: 600;
}

input {
    &[type="text"]:focus, &[type="password"]:focus, &[type="email"]:focus {
        border: 1px solid lighten($grey, 41%);
        background: $white;
        color: $input-color;
        -webkit-transition: all 0.1s ease-in-out;
        -moz-transition: all 0.1s ease-in-out;
        -o-transition: all 0.1s ease-in-out;
        -ms-transition: all 0.1s ease-in-out;
        transition: all 0.1s ease-in-out;
    }
}

textarea:focus {
    border: 1px solid lighten($grey, 41%);
    background: $white;
    color: $input-color;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: lighten($grey, 6%);
    opacity: 1;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: lighten($grey, 6%);
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: lighten($grey, 6%);
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: lighten($grey, 6%);
    opacity: 1;
}

textarea {
    min-height: 60px;
}

label, legend {
    display: block;
    font-weight: bold;
    font-size: 14px;
    font-weight: normal;
}

input {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;

    &[type="checkbox"] {
        display: inline-block;
    }
}

label span, legend span {
    font-weight: normal;
    font-size: 14px;
    color: darken($grey, 27%);
}

.bold {
    font-weight: bold;
}

.hr {
    border-bottom: 1px solid lighten($grey, 39%);
    margin: 10px 0;
}