body{
    &.dir-rtl{

        direction: rtl;
        .menu > li {
            float:right;
        }
        .text-center{
            text-align: right !important;
        }
        button{
            text-align:right;
        }
        .oi-page-content{
            ul{
                padding-left: 0;
                padding-right: 40px;
            }
        }
        .article-content{
            ul{
                padding-left: 0;
                padding-right: 20px;
            }
        }
        .intl-tel-input{
            .selected-dial-code{
                padding-left: 0;
                padding-right: 28px;
            }
            .selected-flag{
                padding: 0 8px 0 0;
                .iti-arrow{
                    right: initial;
                    left: 6px;
                }
            }
        }
        .top-bar-menu{
            float: left !important;
            li{
                margin: 0 0 0 20px !important;
            }
            .arrow{
                left: 0;
                right: 6px;
            }
        }
        .newsletter-btn{
            float: left;
        }
        input.newsletter{
            float: right;
        }
        .social-icons{
            .instagram::before, .instagram i{
                margin: 11px 10px 0 0 !important;
            }
            .facebook::before, .facebook i{
                margin: 11px 12px 0 0 !important;
            }
        }
        .top-search button{
            text-align:center !important;
        }
        .headline{
            float:right;
        }
        .qtyplus::before, .qtyminus::before{
            left:-15px !important;
        }
        .cart-remove::before{
            left:-15px !important;
        }

        .checkbox{
            label{
                display: inline-block;
                vertical-align: middle;
                position: relative;
                padding-right: 5px !important;
                white-space: normal;

                &::before{
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    right: 0 !important;
                    top: 2px;
                    margin-right: -20px !important;
                    border: 1px solid $light-grey;
                    border-radius: 3px;
                    background-color: #fff;
                    @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
                }

                &::after{
                    display: inline-block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    right: 1px !important;
                    top: -2px;
                    margin-right: -20px;
                    padding-right: 3px !important;
                    padding-top: 1px;
                    font-size: 11px;
                    color: $input-color;
                }
            }

            input[type="checkbox"],
            input[type="radio"] {
                opacity: 0;
                z-index: 1;

                &:focus + label::before{
                    @include tab-focus();
                }

                &:checked + label::after{
                    font-family: $font-family-icon;
                    content: $check-icon;
                }

                &:indeterminate + label::after{
                    display: block;
                    content: "";
                    width: 10px;
                    height: 3px;
                    background-color: #555555;
                    border-radius: 2px;
                    margin-left: -16.5px;
                    margin-top: 7px;
                }

                &:disabled + label{
                    opacity: 0.65;

                    &::before{
                        background-color: $input-bg-disabled;
                        cursor: not-allowed;
                    }
                }

            }

            &.checkbox-circle label::before{
                border-radius: 50%;
            }

            &.checkbox-inline{
                margin-top: 0;
            }
        }


        .radio{
            white-space: nowrap;
            label{
                display: inline-block;
                vertical-align: middle;
                position: relative;
                padding-right: 5px !important;
                white-space: normal;

                &::before{
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    right: 0 !important;
                    top: 2px;
                    margin-right: -20px !important;
                    border: 1px solid $light-grey;
                    border-radius: 50%;
                    background-color: #fff;
                    @include transition(border 0.15s ease-in-out);
                }

                &::after{
                    display: inline-block;
                    position: absolute;
                    content: " ";
                    width: 11px;
                    height: 11px;
                    right: 4px;
                    top: 6px;
                    margin-right: -20px !important;
                    border-radius: 50%;
                    background-color: $input-color;
                    @include scale(0, 0);

                    @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
                    //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
                }
            }

            input[type="radio"]{
                opacity: 0;
                z-index: 1;
                margin: 0;

                &:focus + label::before{
                    @include tab-focus();
                }

                &:checked + label::after{
                    @include scale(1, 1);
                }

                &:disabled + label{
                    opacity: 0.65;

                    &::before{
                        cursor: not-allowed;
                    }
                }

            }

            &.radio-inline{
                margin-top: 0;
            }
        }






    }

}