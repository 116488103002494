.nor-page {
  .nor-button {
    margin-bottom: 22px;
  }
  .nor-help {
    display: none;
    margin-bottom: 32px;
    ol {
      margin: 20px 0 20px 30px;
      li {
        line-height: 22px;
      }
    }
  }
  .nor-file {
    margin-bottom: 24px;
  }
  .nor-grid {
    .form-control {
      width: 100%;
    }
    .nor-product-name {
      display: flex;
      align-items: center;
      .item-name {
        margin-left: 15px;
        width: 100%;
      }
    }
    .nor-attached-files {
      list-style: disc;
      word-break: break-all;
      li {
        line-height: 24px;
      }
    }
  }

}
