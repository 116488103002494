
.to-uppercase{
    text-transform: uppercase !important;
}
.to-lowercase{
    text-transform: lowercase !important;
}

.input-100 {
    width: 100% !important;
}

.text-left{
    text-align: left !important;
}
.text-right{
    text-align: right !important;
}
.text-center{
    text-align: center !important;
}
.float-right{
    float:right;
}
.float-left{
    float:left;
}

.hidden{
    display: none;
}
.visible{
    display: block;
}

@media (max-width: 767px) {
    .hidden-xs,
    .hidden-xs-up,
    .hidden-lg-down,
    .hidden-md-down,
    .hidden-sm-down {
        display: none !important;
    }

    .text-right-xs-up,
    .text-right-lg-down,
    .text-right-md-down,
    .text-right-sm-down {
        text-align: right !important;
    }

    .text-left-xs-up,
    .text-left-lg-down,
    .text-left-md-down,
    .text-left-sm-down {
        text-align: left !important;
    }

    .input-100-xs,
    .input-100-xs-up,
    .input-100-lg-down,
    .input-100-md-down,
    .input-100-sm-down {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-lg-down,
    .hidden-md-down,
    .hidden-sm-down {
        display: none !important;
    }

    .input-100-xs-up,
    .input-100-sm-up,
    .input-100-lg-down,
    .input-100-md-down,
    .input-100-sm-down {
        width: 100% !important;
    }

    .text-right-xs-up,
    .text-right-sm-up,
    .text-right-lg-down,
    .text-right-md-down,
    .text-right-sm-down {
        text-align: right !important;
    }

    .text-left-xs-up,
    .text-left-sm-up,
    .text-left-lg-down,
    .text-left-md-down,
    .text-left-sm-down {
        text-align: left !important;
    }

    .menubar-unfold .dashboard-head-container {
        display: none !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-md-up,
    .hidden-lg-down,
    .hidden-md-down {
        display: none !important;
    }

    .input-100-xs-up,
    .input-100-sm-up,
    .input-100-md-up,
    .input-100-lg-down,
    .input-100-md-down {
        width: 100% !important;
    }

    .text-right-xs-up,
    .text-right-sm-up,
    .text-right-md-up,
    .text-right-lg-down,
    .text-right-sm-down {
        text-align: right !important;
    }

    .text-left-xs-up,
    .text-left-sm-up,
    .text-left-md-up,
    .text-left-lg-down,
    .text-left-sm-down {
        text-align: left !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg,
    .hidden-xs-up,
    .hidden-sm-up,
    .hidden-md-up,
    .hidden-lg-down {
        display: none !important;
    }

    .input-100-xs-up,
    .input-100-sm-up,
    .input-100-md-up,
    .input-100-lg-down {
        width: 100% !important;
    }

    .text-right-xs-up,
    .text-right-sm-up,
    .text-right-md-up,
    .text-right-lg-down {
        text-align: right !important;
    }

    .text-left-xs-up,
    .text-left-sm-up,
    .text-left-md-up,
    .text-left-lg-down {
        text-align: left !important;
    }
}
/** RESPONSIVE TABLE **/
.table-responsive {
    .fixed-column{
        position: absolute;
        display: inline-block;
        width: auto;
        border-right: 1px solid #ddd;
        td{
            background-color: #fff;
        }

    }
}
@include screen-md-up{
    .table-responsive {
        .fixed-column{
            display: none;
        }
    }
}

@include screen-xs{
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    #no-more-tables tr { border: 1px solid #ccc; }

    #no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        text-align:left;
    }

    #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align:left;
        font-weight: bold;
    }

    /*
    Label the data
    */
    #no-more-tables td:before { content: attr(data-title);line-height: 46px;}
}
.no-border{
    border:none !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.rotating {
    -webkit-animation: rotating 0.5s linear infinite;
    -moz-animation: rotating 0.5s linear infinite;
    -ms-animation: rotating 0.5s linear infinite;
    -o-animation: rotating 0.5s linear infinite;
    animation: rotating 0.5s linear infinite;
}

.justify-content-flex-end {
    justify-content: flex-end !important;
}