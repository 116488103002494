//
// Checkboxes
// --------------------------------------------------


$font-family-icon: 'FontAwesome' !default;
$fa-var-check: "\f00c" !default;
$check-icon: $fa-var-check !default;

@mixin checkbox-variant($parent, $color) {
  #{$parent} input[type="checkbox"]:checked + label,
  #{$parent} input[type="radio"]:checked + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after{
      color: #fff;
    }
  }
}

@mixin checkbox-variant-indeterminate($parent, $color) {
  #{$parent} input[type="checkbox"]:indeterminate + label,
  #{$parent} input[type="radio"]:indeterminate + label {
    &::before {
      background-color: $color;
      border-color: $color;
    }
    &::after{
      background-color: #fff;
    }
  }
}



.checkbox{
  white-space: nowrap;
  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    white-space: normal;

    &::before{
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      top: 2px;
      margin-left: -20px;
      border: 1px solid $checkbox-border;
      border-radius: 3px;
      background-color: $mobilklimak-dark-blue;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 1px;
      top: -2px;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: $dark-grey2;
    }
  }
  .help-block{
    white-space: normal;
  }
  &.base{
    label{
      &::before{
        background-color: $checkbox-background;
        @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
      }

      &::after{
        color:$input-color;
      }
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    z-index: 1;

    &:focus + label::before{
      @include tab-focus();
    }

    &:checked + label::after{
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:indeterminate + label::after{
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }
    }

  }

  &.checkbox-circle label::before{
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }
}

//
// Radios
// --------------------------------------------------

@mixin radio-variant($parent, $color) {
  #{$parent} input[type="radio"]{
    + label{
      &::after{
        background-color: $color;
      }
    }
    &:checked + label{
      &::before {
        border-color: $color;
      }
      &::after{
        background-color: $color;
      }
    }
  }
}

.radio{
  white-space: nowrap;
  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    white-space: normal;

    &::before{
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      top: 2px;
      margin-left: -20px;
      border: 1px solid $light-grey;
      border-radius: 50%;
      background-color: #fff;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      content: " ";
      width: 11px;
      height: 11px;
      left: 4px;
      top: 6px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $mobilklimak-dark-blue;
      @include scale(0, 0);

      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }
  .help-block{
    white-space: normal;
  }

  input[type="radio"]{
    opacity: 0;
    z-index: 1;
    margin: 0;

    &:focus + label::before{
      @include tab-focus();
    }

    &:checked + label::after{
      @include scale(1, 1);
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }
}



input[type="checkbox"],
input[type="radio"] {
  &.styled:checked + label:after {
    font-family: $font-family-icon;
    content: $check-icon;
  }
  .styled:checked + label {
    &::before {
      color: #fff;
    }
    &::after {
      color: #fff;
    }
  }
}
