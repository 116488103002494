.faq-page {
  ul {
    list-style: initial;
    padding-left: 40px;
  }

  .faq-group {
    margin-bottom: 34px;
    h2 {
      margin-bottom: 14px;
    }
  }

  .faq-widget-product {
    .twelve.columns {
      width: 100%;
      padding: 5px;
    }
    background-color: #ffffff;
    color: red;
    padding-top: 10px;
    padding-left: 10px;
  }
}