.main-hero {
  padding: 210px 0;
  background-image: url("/images/hero-image.jpg");
  background-size: cover;
  background-position: center;
  margin-bottom: 25px;
  @include screen-sm-down{
    padding: 150px 0;
  }
  h1 {
    color: $mobilklimak-main;
    font-size: 42px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 25px;
  }
  p {
    color: $white;
    background-color: rgba(3, 162, 202, 0.7);
    font-size: 20px;
    font-weight: 500;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}