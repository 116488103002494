.collapse-offset {
  position: relative;
  z-index: 100;
  .collapse-content {
    position: relative;
    &.collapse-hidden {
      height: 120px;
      overflow-y: hidden;
      transition: 0.5s;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: -webkit-linear-gradient(top,rgba(255,255,255,0) 33%,#fff 100%);
        background-image: linear-gradient(to bottom,rgba(255,255,255,0) 33%,#fff 100%);
        background-repeat: repeat-x;
        pointer-events: none;
        transition: 0.5s;
      }
    }
  }
  .collapse-read-more {
    margin-top: 10px;

  }
  margin-bottom: 15px;
}